













import { computed, defineComponent } from "@vue/composition-api";
import useWatchPropsValue from "@/apps/core/components/useWatchPropsValue";

export default defineComponent({
  name: "CariInput",
  props: { value: String },
  setup(props, { emit }) {
    const { valueRef } = useWatchPropsValue(props);
    const computedValue = computed({
      get() {
        return valueRef.value ?? "";
      },
      set(value: string) {
        valueRef.value = value.trim() ?? null;
        emit("input", value);
      },
    });

    return {
      computedValue,
    };
  },
});
